<template>
  <div class="container__indicators">
    <div>
      <div>
        <v-avatar
          color="blue lighten-4 mx-5 circle_border"
          size="20"
        ></v-avatar>
        Asesor recientemente asignado
      </div>
      <div>
        <v-avatar color="disabled lighten-4 mx-5" size="20"></v-avatar>
        Asesor desactivado
      </div>
    </div>
    <div justify="center">
      <div>
        <v-icon light color="green darken-3" class="page__grab-icon">
          mdi-play
        </v-icon>
        Siguiente en asignar
      </div>
      <div
        class="d-flex align-center
"
      >
        <div class="px-2"><IndicatorCurrentGuard /></div>
        Guardia en curso
      </div>
    </div>
  </div>
</template>

<script>
import IndicatorCurrentGuard from "./IndicatorCurrentGuard.vue";

export default {
  name: "ListIndicators",
  components: {
    IndicatorCurrentGuard
  }
};
</script>

<style scoped>
.container__indicators {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0;
}
.disabled {
  background: #e9e9e9;
}
</style>
