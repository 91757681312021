<template>
  <v-tooltip top>
    <template #activator="{ on, attrs }">
      <div class="container_activator" v-bind="attrs" v-on="on">
        <slot />
      </div>
    </template>
    <slot name="message" />
  </v-tooltip>
</template>

<script>
export default {
  name: "HelperMessage",
  data: () => ({})
};
</script>

<style scoped>
.container_activator {
  padding: 0;
  margin: 0;
  max-width: min-content;
  display: flex;
}
</style>
