<template>
  <v-card width="100vw" height="90vh" elevation="0">
    <v-container fill-height>
      <v-row justify="center" align="center">
        <v-col cols="12" justify="center" align="center">
          <v-img contain :src="noDataImg" height="40vh" width="90vw"> </v-img>
        </v-col>
        <v-col class="pa-5" cols="12" justify="center" align="center">
          <div>
            Asignación por carrusel o manual.
          </div>
          <div>
            Actualmente la asignación de Leads esta en modo manual. Consulte con
            soporte para activar el modo carrusel y crear tu primera tabla de
            asignación.
          </div>
        </v-col>
        <v-col cols="12" justify="center" align="center">
          <v-btn
            text
            class="ma-2 btn-info"
            @click="showInformation = !showInformation"
          >
            Mas información
            <v-icon right dark>
              mdi-information-outline
            </v-icon>
          </v-btn>
          <div v-if="showInformation">
            <div class="my-4 grey--text text--darken-2">
              Asignación manual: Los Leads nuevos entran como "Por asginar" de
              modo que un administrador debe asignarlos a un asesor manualmente.
            </div>

            <div class="my-4 grey--text text--darken-2">
              Asignación Carrusel: Un Lead nuevo se asigna automaticamnete a un
              asesor dependiendo de un orden. Este orden puede verse en una
              tabla la cual el administrador puede cambiar.
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "MessageTypeAssignation",
  data() {
    return {
      noDataImg: `${process.env.BASE_URL}img/no_data.png`,
      showInformation: false
    };
  }
};
</script>
