import { render, staticRenderFns } from "./IndicatorCurrentGuard.vue?vue&type=template&id=4d6b0d90&scoped=true&"
import script from "./IndicatorCurrentGuard.vue?vue&type=script&lang=js&"
export * from "./IndicatorCurrentGuard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d6b0d90",
  null
  
)

export default component.exports