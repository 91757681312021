<template>
  <HelperMessage>
    <IndicatorPulse />
    <template #message>
      Guardia en curso
    </template>
  </HelperMessage>
</template>

<script>
import IndicatorPulse from "../../common/IndicatorPulse.vue";
import HelperMessage from "../../common/HelperMessage.vue";
export default {
  name: "IndicatorCurrentGuard",
  components: {
    IndicatorPulse,
    HelperMessage
  },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style scoped></style>
