<template>
  <span class="pulse circle"></span>
</template>

<script>
export default {
  name: "IndicatorPulse",
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style scoped>
.circle {
  display: inline-block;
  background-color: #4caf50;
  border-radius: 50%;
  height: 9px;
  width: 9px;
}
.pulse {
  animation: pulse-animation 1.5s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}
</style>
