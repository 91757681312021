<template>
  <div>
    <v-btn
      color="primary"
      small
      outlined
      elevation
      v-if="!existGuard(time_range)"
      @click="modal = true"
    >
      <span>{{ getTimeGuard(time_range) }}</span>
    </v-btn>
    <v-menu v-else top offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          small
          outlined
          elevation
          v-bind="attrs"
          v-on="on"
        >
          <span>{{ getTimeGuard(time_range) }}</span>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="modal = true" class="action">
          <v-list-item-title>Editar</v-list-item-title>
        </v-list-item>
        <v-list-item
          class="action"
          v-if="existGuard(time_range)"
          @click="clearGuard"
        >
          <v-list-item-title>Limpiar</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- Dialog -->
    <v-dialog ref="dialog" v-model="modal" width="800">
      <v-card>
        <v-card-title>
          <span class="headline">Horario de guardia</span>
        </v-card-title>
        <v-card-text>
          Asigna un horario de guardia para este asesor. Mientras el horario de
          guardia esté activo, todos los leads se le asignarán a este asesor.
        </v-card-text>
        <div class="container_picker">
          <div>
            <h4>Start:</h4>
            <v-time-picker
              elevation="1"
              format="24hr"
              v-model="start"
              :max="end"
            />
          </div>
          <div>
            <h4>End:</h4>
            <v-time-picker
              elevation="1"
              format="24hr"
              v-model="end"
              :min="start"
            />
          </div>
        </div>

        <v-card-actions>
          <v-card-text class="pb-0">
            <p
              v-if="message.text"
              dense
              outlined
              :class="`${message.color}--text` + ' mb-0'"
            >
              {{ message.text }}
            </p>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-btn text @click="modal = false">Cancel</v-btn>
          <v-btn text :disabled="!valid" @click="saveGuard">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { updateTimeRangeByContact } from "../../../api/contacts-api";
import { mapGetters } from "vuex/dist/vuex.common.js";

export default {
  name: "ColumnGuard",
  components: {},
  props: {
    idAllocation: String,
    idContact: String,
    time_range: Object,
    current_time: Number,
    contact_list: Array
  },
  data() {
    return {
      modal: false,
      start: this.minutesToTime(this.time_range?.start),
      end: this.minutesToTime(this.time_range?.end),
      valid: this.validate(),
      message: {
        text: "",
        color: ""
      }
    };
  },
  computed: {
    ...mapGetters("contacts", {
      contactById: "contactById"
    })
  },
  watch: {
    time_range() {
      this.start = this.minutesToTime(this.time_range?.start);
      this.end = this.minutesToTime(this.time_range?.end);
    },
    start() {
      this.validate();
    },
    end() {
      this.validate();
    }
  },
  methods: {
    existGuard(time_range) {
      if (time_range?.start || time_range?.end) return true;
      return false;
    },
    getTimeGuard(time_range) {
      if (!this.existGuard(time_range)) return "N/A";

      const start = this.minutesToTime(time_range.start);
      const end = this.minutesToTime(time_range.end);
      return `${start} - ${end}`;
    },
    minutesToTime(minutes) {
      if (!minutes) return null;
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      const format = value => String(value).padStart(2, "0");

      return `${format(hours)}:${format(remainingMinutes)}`;
    },
    timeToMinutes(time) {
      if (!time) return 0;
      const [hours, minutes] = time.split(":");
      return Number(hours) * 60 + Number(minutes);
    },
    async saveGuard() {
      const start = this.timeToMinutes(this.start);
      const end = this.timeToMinutes(this.end);
      const body = {
        contactId: this.idContact,
        allocationId: this.idAllocation,
        time_range: { start, end }
      };
      const res = await this.update(body);
      if (res) this.modal = false;
    },
    async clearGuard() {
      const body = {
        contactId: this.idContact,
        allocationId: this.idAllocation,
        time_range: { start: 0, end: 0 }
      };
      await this.update(body);
    },
    validate() {
      const validate = this.validateTimeRange();
      this.valid = this.start && this.end && validate;
    },
    // revisar si el rango de tiempo se cruza con otro rango de tiempo
    // de otro contacto
    validateTimeRange() {
      const start = this.timeToMinutes(this.start);
      const end = this.timeToMinutes(this.end);
      const time_range = { start, end };

      for (const contact of this.contact_list) {
        if (!contact.time_range || contact.idContact === this.idContact)
          continue;
        const { start, end } = contact.time_range;
        // Esta permitido que la hora de inicio sea igual a la hora de fin de otro contacto
        // y viceversa
        // Ejemplo: contacto 1: 08:00 - 12:00, contacto 2: 12:00 - 16:00
        // En este caso, el contacto 2 puede tener la hora de inicio igual a la hora de fin del contacto 1
        // y viceversa
        const is_crossed =
          (time_range.start >= start && time_range.start < end) ||
          (time_range.end > start && time_range.end <= end);
        if (!is_crossed) continue;
        const name = this.contactById(contact.idContact).name;
        this.message = {
          text: `El horario de guardia se cruza con el horario de guardia de ${name}`,
          color: "error"
        };
        return false;
      }
      this.message = { text: "", color: "" };
      return true;
    },
    async update(body) {
      const res = await updateTimeRangeByContact(body);
      if (!res.Data) {
        this.$snotify.error("Error al actualizar la guardia", "Error");
        return false;
      }
      const { start, end } = body.time_range;
      this.$emit("save-guard", { start, end });
      this.$snotify.success("Guardia actualizada correctamente", "Éxito");
      return true;
    }
  }
};
</script>

<style scoped>
.container_picker {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.action {
  cursor: pointer;
}

.action:hover {
  background-color: #f7f7f7;
}
</style>

<style>
.theme--light.v-time-picker-clock {
  background: #f0d6ff;
}
</style>
