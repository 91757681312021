<template>
  <div>
    <v-card v-if="isLoading" width="100vw" height="90vh" elevation="0">
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col cols="12" justify="center" align="center">
            <v-progress-circular
              :width="5"
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
          <v-col cols="12" justify="center" align="center">
            <div v-if="loadingStatus == ''">
              Cargando ...
            </div>
            <div v-else>
              {{ loadingStatus }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <div v-else>
      <MessageTypeAssignationVue
        v-if="contactAllocation === null || contactAllocation.length === 0"
      />
      <MessageTypeAssignationVue
        v-else-if="realEstateGroup && realEstateGroup.assignment_method === 0"
      />
      <div v-else class="pa-5">
        <h2 class="text-h5">
          Carrouseles de asignación
        </h2>
        <div v-for="(location, index) in contactAllocation" :key="index">
          <v-row class="pt-10 px-10" justify="space-between" align="center">
            <v-col class="pa-5">
              <v-row>
                <div class="text-h5 pb-2">
                  {{ location.name }}
                  <v-tooltip
                    top
                    v-if="
                      location.contact_list.every(
                        contact => contact.status === 2
                      )
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        class="ma-2"
                        color="pink accent-3"
                        outlined
                      >
                        <v-icon left>
                          mdi-alert-decagram
                        </v-icon>
                        <span>Asesores inactivos</span>
                      </v-chip>
                    </template>
                    <div>
                      Los Leads que ingresen al sistema estarán sin asignar
                    </div>
                    <div>debido a que no hay asesores activos</div>
                  </v-tooltip>
                </div>

                <div v-if="location.name === 'General'" class="mx-2">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span
                      >Los Leads que ingresen al sistema que no especifiquen el
                      desarrollo de interés, se asignará a un asesor que
                      pertenezca a esta tabla.</span
                    >
                  </v-tooltip>
                </div>
              </v-row>
              <v-row
                class="aliases-container"
                v-if="location.name_aliases && location.name_aliases.length > 0"
              >
                <div class="container-tags-name">
                  <v-icon left color="#000000de">mdi-label</v-icon>
                  Aliases:
                </div>
                <v-chip
                  v-for="(tag, index) in location.name_aliases"
                  :key="index"
                  outlined
                  :color="colorComputed"
                  small
                >
                  {{ tag }}
                </v-chip>
              </v-row>
            </v-col>
            <div
              v-if="
                contactListToAdd(contactList, location.contact_list).length > 0
              "
              class="pa-5"
            >
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="blue" outlined dark v-bind="attrs" v-on="on">
                    + Añadir asesor
                  </v-btn>
                </template>
                <v-list>
                  <div
                    v-for="(item, index) in contactListToAdd(
                      contactList,
                      location.contact_list
                    )"
                    :key="index"
                  >
                    <v-btn
                      block
                      elevation="0"
                      class="pa-2 text-capitalize subtitle-2"
                      @click="addToAllocation(item, location._id)"
                      ><div>
                        {{ item.name }}
                      </div>
                      <v-spacer />
                    </v-btn>
                  </div>
                </v-list>
              </v-menu>
            </div>
            <div v-else>
              <v-btn color="black" disabled outlined light class="my-10 px-5">
                No hay Asesores para añadir.
              </v-btn>
            </div>
          </v-row>

          <v-data-table
            v-if="getTotalTable(location, location.contact_list) > 0"
            dense
            dark
            hide-default-footer
            class="elevation-1 blue-grey darken-4 black--text"
            :headers="headers"
            :items="location.contact_list"
            :items-per-page="location.contact_list.length"
          >
            <template v-slot:body="props">
              <!-- <draggable
                    :list="props.items"
                    tag="tbody"
                    @end="checkEnd($event, location._id, location.contact_list)"
                  > -->
              <tr
                v-for="(item, index) in props.items"
                :key="index"
                :class="coloBackground(item, location)"
              >
                <td v-if="item.status !== 0">
                  <v-col justify="center" align="center">
                    <v-icon
                      v-if="
                        nextAssign(location) === item.idContact &&
                          !guardInProcess(location.contact_list)
                      "
                      large
                      color="green darken-3"
                      light
                      class="page__grab-icon"
                    >
                      mdi-play
                    </v-icon>
                    <IndicatorCurrentGuard
                      v-if="
                        guardInProcess(location.contact_list)?.idContact ===
                          item.idContact
                      "
                    />
                  </v-col>
                </td>
                <td v-if="item.status !== 0">
                  {{ index + 1 }}
                </td>
                <td v-if="item.status !== 0">
                  <v-col>
                    {{ getDataById(item.idContact).name }}
                  </v-col>
                </td>
                <td v-if="item.status !== 0">
                  <v-col>
                    {{ getDataById(item.idContact).email }}
                  </v-col>
                </td>
                <td v-if="item.status !== 0">
                  <v-col
                    v-if="
                      getDataById(item.idContact) &&
                        getDataById(item.idContact).performance
                    "
                  >
                    {{ getDataById(item.idContact).performance.total }}
                  </v-col>
                </td>
                <td v-if="item.status !== 0">
                  <v-col>
                    <ColumnGuard
                      :idAllocation="location._id"
                      :idContact="item.idContact"
                      :time_range="item.time_range"
                      :current_time="getCurrentTime"
                      :contact_list="location.contact_list"
                      @save-guard="
                        time_range =>
                          saveGuard(time_range, item.idContact, location)
                      "
                    />
                  </v-col>
                </td>
                <td v-if="item.status !== 0">
                  <v-col>
                    <v-tooltip top color="primary">
                      <template v-slot:activator="{ on, att }">
                        <v-checkbox
                          v-if="
                            getDataById(item.idContact).status === 0 ||
                              getDataById(item.idContact).status === 3
                          "
                          v-bind="att"
                          v-on="on"
                          :input-value="
                            disponibility(getDataById(item.idContact).status)
                          "
                          color="indigo darken-3"
                          disabled
                          light
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-else
                          v-bind="att"
                          v-on="on"
                          :input-value="
                            disponibility(getDataById(item.idContact).status)
                          "
                          color="indigo darken-3"
                          light
                          hide-details
                          @change="
                            changeDisponibility(
                              location,
                              getDataById(item.idContact),
                              getDataById(item.idContact).status
                            )
                          "
                        ></v-checkbox>
                      </template>
                      <div class="py-2 warning--text">
                        Cambiar disponibilidad
                      </div>
                      <div>Nota:</div>
                      <div>
                        Desmarcar la casilla afectará al asesor de forma que no
                        podrá recibir Leads
                      </div>
                      <div>
                        de ninguna tabla en la que este asignado hasta que se
                        vuelva a marcar.
                      </div>
                    </v-tooltip>
                  </v-col>
                </td>
                <td v-if="item.status !== 0">
                  <v-tooltip top color="blue-grey darken-3">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        light
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          removeFromAllocation(
                            getDataById(item.idContact),
                            location._id
                          )
                        "
                      >
                        <v-icon light small>
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </template>
                    <div class="py-2 red--text text--accent-1 ">
                      Eliminar asesor de esta tabla.
                    </div>
                    <div>Nota:</div>
                    <div>
                      - Al eliminarlo dejará de recibir Leads de este
                      desarrollo/tabla.
                    </div>
                    <div>
                      Puedes añadirlo nuevamente en otro momento para que todo
                    </div>
                    <div>
                      vuelva a la normalidad.
                    </div>
                  </v-tooltip>
                </td>
              </tr>
              <!-- </draggable> -->
            </template>
          </v-data-table>

          <v-data-table
            v-else
            dark
            hide-default-footer
            class="elevation-4 blue-grey darken-4 black--text"
            :headers="headers"
            :items="[]"
          >
            <template v-slot:no-data>
              <v-container fluid>
                <div>
                  No hay asesores asignados a esta tabla
                </div>
              </v-container>
            </template>
          </v-data-table>
        </div>
      </div>
      <ListIndicators class="sticky_notes" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ColumnGuard from "./components/columnGuard.vue";
import IndicatorCurrentGuard from "./components/IndicatorCurrentGuard.vue";
import ListIndicators from "./components/ListIndicators.vue";
import MessageTypeAssignationVue from "./components/MessageTypeAssignation.vue";
export default {
  components: {
    ColumnGuard,
    IndicatorCurrentGuard,
    MessageTypeAssignationVue,
    ListIndicators
  },
  data() {
    return {
      timeoutId: null,
      intervalId: null,
      currentDateTime: new Date(),
      showInformation: false,
      isCreatingTable: true,
      statusTableCreate: true,
      isActiveCarrusel: false,
      statusActiveCarrusel: "",
      noDataImg: `${process.env.BASE_URL}img/no_data.png`,
      headers: [
        {
          text: "",
          value: "",
          sortable: false
        },
        {
          text: "#",
          value: "",
          sortable: false
        },
        {
          text: "Asesor",
          value: "name",
          sortable: false
        },
        {
          text: "Correo",
          value: "email",
          sortable: false
        },
        {
          text: "Leads asignados",
          value: "index_postition",
          sortable: false
        },
        {
          text: "Guardia",
          value: "time_range.start",
          sortable: false
        },
        {
          text: "Disponible",
          value: "status",
          sortable: false
        },
        {
          text: "Acciones",
          value: "action",
          sortable: false
        }
      ],

      //Variable para obtener datos
      loadingStatus: "",
      tabledUpdatingId: "",
      isLoading: false,
      isUpdating: false,
      hasError: false,
      contactList: [],
      sourcesList: [],
      contactSource: null,
      contactListSelect: [],
      nameAllocation: "",
      tmpContactList: [],
      contactAllocation: [],
      tmpContactAllocation: [],
      realEstateList: [],
      realEstateGroup: null,
      stepCreatingTable: "",
      dialog: false
    };
  },
  mounted() {
    this.updateCurrentDateTime();
    this.syncIntervalToMinute();
    this.getContactList();
    this.getTrafficData();
  },
  methods: {
    ...mapActions({
      fetchContactStore: "contacts/fetchContactList",
      fetchContactAllocationStore: "contacts/fetchContactListAllocation",
      fetchRealEstateListStore:
        "realEstateDevelopments/getRealEstateDevAssgign",
      fetchRealEstateGroupStore: "realEstateGroup/fecthRealEstateGroupDetail",
      updateContactAllocationStore: "contacts/updateContactListAllocation",
      updateRealEstateGroupStore: "realEstateGroup/updateRealEstateGroup",
      postCreateContactListLocationStore:
        "contacts/postCreateContactListLocation",
      updateContactByIdStore: "contacts/updateContactById",
      getTrafficDataStore: "getTrafficData"
    }),
    getContactsByStatus(list) {
      let final = list.filter(l => {
        return l.status !== 0;
      });
      return final.length;
    },
    getContactList() {
      this.isLoading = true;
      this.loadingStatus = "Obteniendo Asesores";
      this.fetchContactStore()
        .then(t => {
          this.contactList = t.ContactsList;
          this.tmpContactList = t.ContactsList;
          this.getRealEstateList();
        })
        .catch(function() {
          this.contactList = [];
          this.tmpContactList = [];
          this.hasError = true;
          this.isLoading = false;
        });
    },
    getRealEstateList() {
      this.loadingStatus = "Obteniendo Desarrollos";
      this.fetchRealEstateListStore()
        .then(t => {
          this.hasError = false;
          this.realEstateList = t.response.data.RealEstateDevelopment;
          this.getContactAllocation();
        })
        .catch(function() {
          this.realEstateList = [];
          this.hasError = true;
          this.isLoading = false;
        });
    },
    getContactAllocation() {
      this.loadingStatus = "Obteniendo Tablas";
      this.fetchContactAllocationStore()
        .then(t => {
          this.contactAllocation = t.response.data.Allocation;
          this.tmpContactAllocation = t.response.data.Allocation;
          this.hasError = false;
          this.getRealEstateGroup();
        })
        .catch(function() {
          this.contactAllocation = [];
          this.tmpContactAllocation = [];
          this.hasError = true;
          this.isLoading = false;
        });
    },
    getRealEstateGroup() {
      this.loadingStatus = "Obteniendo datos de la inmobiliaria";
      this.fetchRealEstateGroupStore()
        .then(g => {
          this.realEstateGroup = g.RealEstateGroup;
          this.hasError = false;
          this.isLoading = false;
        })
        .catch(function() {
          this.realEstateGroup = null;
          this.hasError = true;
          this.isLoading = false;
        });
    },
    checkEnd: function(evt, idAllocation, list) {
      this.isUpdating = true;
      this.tabledUpdatingId = idAllocation;
      let index = this.contactAllocation.findIndex(t => {
        return t._id == idAllocation;
      });
      list.splice(
        evt.oldIndex,
        1,
        list.splice(evt.newIndex, 1, list[evt.oldIndex])[0]
      );
      this.contactAllocation[index].contact_list = list;

      this.updateContactAllocationStore({
        idAllocation: this.contactAllocation[index]._id,
        real_estate_group_id: this.contactAllocation[index]
          .real_estate_group_id,
        name: this.contactAllocation[index].name,
        contact_list: this.contactAllocation[index].contact_list,
        index_position: this.contactAllocation[index].index_position,
        last_index_assigned: this.contactAllocation[index].last_index_assigned,
        old_index_assigned: this.contactAllocation[index].old_index_assigned
      }).then(() => {
        this.isUpdating = false;
        this.tabledUpdatingId = "";
      });
    },
    contactListToAdd(contactList, locationContatList) {
      let listToAdd = [];
      let idsAllocation = [];
      let idsContactlist = [];
      let idsToAdd = [];

      let tmpContactList = contactList.filter(c => {
        return c.status === 1 || c.status === 2;
      });

      for (let x = 0; x < tmpContactList.length; x++) {
        idsContactlist.push(tmpContactList[x]._id);
      }

      let tmpAllocation = locationContatList.filter(a => {
        return a.status === 1 || a.status === 2;
      });

      for (let i = 0; i < tmpAllocation.length; i++) {
        idsAllocation.push(tmpAllocation[i].idContact);
      }

      for (let y = 0; y < idsContactlist.length; y++) {
        if (!idsAllocation.includes(idsContactlist[y])) {
          idsToAdd.push(idsContactlist[y]);
        }
      }

      for (let z = 0; z < idsToAdd.length; z++) {
        let index = this.contactList.findIndex(t => {
          return t._id === idsToAdd[z];
        });
        listToAdd.push(this.contactList[index]);
      }

      return listToAdd;
    },
    addToAllocation(item, idAllocation) {
      const self = this;
      let index = this.contactAllocation.findIndex(t => {
        return t._id == idAllocation;
      });
      this.contactAllocation[index].contact_list.push({
        idContact: item._id,
        status: item.status,
        status_assigned: 0
      });
      let tmpRealList = item.real_estate_develop_id;
      if (
        !tmpRealList.includes(
          this.contactAllocation[index].real_estate_develop_id
        )
      ) {
        tmpRealList.push(this.contactAllocation[index].real_estate_develop_id);
      }
      this.updateContactByIdStore({
        role: "admin",
        data: {
          _id: item._id,
          table: item.role,
          role: "admin",
          real_estate_develop_id: tmpRealList
        }
      }).then(() => {
        self
          .updateContactAllocationStore({
            idAllocation: this.contactAllocation[index]._id,
            real_estate_group_id: this.contactAllocation[index]
              .real_estate_group_id,
            name: this.contactAllocation[index].name,
            contact_list: this.contactAllocation[index].contact_list,
            index_position: this.contactAllocation[index].index_position,
            last_index_assigned: this.contactAllocation[index]
              .last_index_assigned,
            old_index_assigned: this.contactAllocation[index].old_index_assigned
          })
          .then(() => {
            this.isUpdating = false;
            this.tabledUpdatingId = "";
          });
      });
    },
    removeFromAllocation(contact, idAllocation) {
      let index = this.contactAllocation.findIndex(t => {
        return t._id == idAllocation;
      });
      let newlist = this.contactAllocation[index].contact_list.filter(
        e => e.idContact != contact._id
      );
      this.contactAllocation[index].contact_list = newlist;
      this.updateContactAllocationStore({
        idAllocation: this.contactAllocation[index]._id,
        real_estate_group_id: this.contactAllocation[index]
          .real_estate_group_id,
        name: this.contactAllocation[index].name,
        contact_list: this.contactAllocation[index].contact_list,
        index_position: this.contactAllocation[index].index_position,
        last_index_assigned: this.contactAllocation[index].last_index_assigned,
        old_index_assigned: this.contactAllocation[index].old_index_assigned
      }).then(() => {
        this.removeFromContactList(contact, this.contactAllocation[index]);
      });
    },
    removeFromContactList(contact, allLocation) {
      if (
        allLocation &&
        allLocation.real_estate_develop_id != undefined &&
        allLocation.real_estate_develop_id != ""
      ) {
        this.updateContactByIdStore({
          role: "admin",
          data: {
            _id: contact._id,
            table: contact.role,
            role: "admin",
            real_estate_develop_id: contact.real_estate_develop_id.filter(i => {
              return i !== allLocation.real_estate_develop_id;
            })
          }
        }).then(res => {
          if (res && res.contact != undefined && res.contact != "") {
            this.isUpdating = false;
            this.tabledUpdatingId = "";
          } else {
            this.isUpdating = false;
            this.tabledUpdatingId = "";
          }
        });
      }
    },
    disponibility(value) {
      // 0 eliminado// 1 activo// 2 inactivo // 3 suspendido
      return value == 1;
    },
    nextAssign(item) {
      let activeContacts = item.contact_list.filter(c => {
        return c.status === 1;
      });
      if (activeContacts.length === 1) {
        return activeContacts[0].idContact;
      } else {
        let final = activeContacts.filter(l => {
          return l.status_assigned === 0;
        });
        if (final.length > 0) {
          return final[0].idContact;
        } else {
          let less = activeContacts.filter(l => {
            return l.status_assigned === 1;
          });
          return less.length > 0 ? less[0].idContact : "";
        }
      }
    },
    changeDisponibility(location, item, value) {
      this.isUpdating = true;
      this.tabledUpdatingId = location._id;
      let index = this.contactList.findIndex(function(items) {
        return items._id == item._id;
      });
      this.contactList[index].status = value === 1 ? 2 : 1;
      let indexAllocation = this.contactAllocation.findIndex(
        all => all._id === location._id
      );
      let indexLocation = location.contact_list.findIndex(
        lo => lo.idContact === this.contactList[index]._id
      );
      location.contact_list[indexLocation].status = this.contactList[
        index
      ].status;
      this.contactAllocation[indexAllocation].contact_list =
        location.contact_list;
      this.nextAssign(location);
      this.updateContactByIdStore({
        role: "admin",
        data: {
          _id: item._id,
          table: item.role,
          role: "admin",
          status: value === 1 ? 2 : 1
        }
      }).then(() => {
        this.isUpdating = false;
        this.tabledUpdatingId = "";
      });
    },
    getDataById(id) {
      let map = this.contactList.find(l => {
        return l._id === id;
      });
      return map === undefined ? {} : map;
    },
    async createTable() {
      await this.activeDeactiveCarrusel("Active");
      if (this.contactList !== null && this.contactList.length !== 0) {
        this.isCreatingTable = true;
        this.stepCreatingTable = "Obteniendo desarrollos asignados ...";

        let idContacts = [];
        let idDevelopContactsAssigned = [];
        let createGeneralTable = false;

        for (let i = 0; i < this.contactList.length; i++) {
          idContacts.push(this.contactList[i]._id);
          if (this.contactList[i].real_estate_develop_id.length === 0) {
            createGeneralTable = true;
          } else {
            for (
              let x = 0;
              x < this.contactList[i].real_estate_develop_id.length;
              x++
            ) {
              idDevelopContactsAssigned.push(
                this.contactList[i].real_estate_develop_id[x]
              );
            }
          }
        }

        this.stepCreatingTable = "Obteniendo tablas de asignación ...";

        let idDevelops = [...new Set(idDevelopContactsAssigned)];
        let developsData = [];

        for (let i = 0; i < idDevelops.length; i++) {
          for (let x = 0; x < this.realEstateList.length; x++) {
            if (this.realEstateList[x]._id === idDevelops[i]) {
              developsData.push(this.realEstateList[x]);
            }
          }
        }

        this.stepCreatingTable = "Creando tablas de asignación ...";

        if (createGeneralTable) {
          this.postCreateContactListLocationStore({
            name: "General",
            contact_list: []
          }).then(res => {
            if (res.response.data.Allocation.length !== 0) {
              this.isCreatingTable = false;
              this.statusTableCreate = "";
            } else {
              this.isCreatingTable = false;
              this.statusTableCreate =
                "Ha ocurrido un error. intentalo de nuevo.";
              setTimeout(() => {
                this.statusTableCreate = "";
              }, 2000);
            }
          });
        }

        for (let i = 0; i < developsData.length; i++) {
          let contactToThisTable = this.contactList.filter(r => {
            return (
              r.real_estate_develop_id.includes(developsData[i]._id) &&
              r.status !== 0
            );
          });
          await this.postCreateContactListLocationStore({
            name: developsData[i].name,
            contact_list: contactToThisTable.map(c => c._id)
          });
        }

        this.isCreatingTable = false;
        window.location.reload();
        setTimeout(() => {
          this.statusTableCreate = "";
          this.isCreatingTable = false;
        }, 3000);
      } else {
        this.statusTableCreate =
          "No se encontraron asesores. Registra un asesor para poder crear una tabla";
        setTimeout(() => {
          this.statusTableCreate = "";
          this.isCreatingTable = false;
        }, 3000);
      }
    },
    activeDeactiveCarrusel(mode) {
      const self = this;
      self.isActiveCarrusel = true;
      setTimeout(() => {
        self
          .updateRealEstateGroupStore({
            name: self.realEstateGroup.name,
            assignment_method: mode === "Active" ? 1 : 0
          })
          .then(() => {
            window.location.reload();
          })
          .catch(() => {
            window.location.reload();
          });
      }, 1500);
    },
    getTotalTable(location, item) {
      let total = 0;
      if (location.name === "General") {
        return 1;
      } else {
        for (let i = 0; i < item.length; i++) {
          if (item[i].status > 0) {
            total = total + 1;
          }
        }
        return total;
      }
    },
    getTrafficData() {
      const self = this;
      self.getTrafficDataStore().then(res => {
        for (let i = 0; i < res.source.length; i++) {
          self.sourcesList.push(res.source[i].source);
        }
      });
    },
    createNewTableAllocation() {
      const self = this;
      const dataCreate = {
        name: self.nameAllocation,
        source_name: self.contactSource,
        marketing_assigned: true,
        contact_list: self.contactListSelect
      };
      this.$snotify.async(
        "Agregando Tabla de Asignacion",
        "Agregando...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              this.postCreateContactListLocationStore(dataCreate)
                .then(() => {
                  resolve({
                    title: "Tabla de Asignacion",
                    body: `Agregado correctamente`,
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 4000
                    }
                  });
                })
                .catch(err => {
                  reject({
                    title: "Error!!!",
                    body: `Ocurrio un problema en el proceso. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally(() => {
                  location.reload();
                });
            }, 500);
          })
      );
    },
    updateCurrentDateTime() {
      this.currentDateTime = new Date();
    },
    syncIntervalToMinute() {
      this.cleanInterval();
      const now = new Date();
      const millisecondsUntilNextMinute =
        (60 - now.getSeconds()) * 1000 - now.getMilliseconds();

      // Espera hasta el próximo minuto exacto
      this.timeoutId = setTimeout(() => {
        this.updateCurrentDateTime();

        // Luego, configura un intervalo que se ejecute cada minuto
        this.intervalId = setInterval(this.updateCurrentDateTime, 60000); // Cada 60 segundos
      }, millisecondsUntilNextMinute);
    },
    cleanInterval() {
      if (this.timeoutId) clearTimeout(this.timeoutId);
      if (this.intervalId) clearInterval(this.intervalId);
    },
    coloBackground(item, location) {
      const disponibility = this.disponibility(
        this.getDataById(item.idContact).status
      );
      if (!disponibility) return "disabled";

      const existGuard = this.guardInProcess(location.contact_list);
      // en caso de que exista un guardia en proceso, se remueve el color de recientemente asignado
      if (item.idContact === location.index_position_user && !existGuard)
        return "recently";
      return "normal";
    },
    // revisa si hay un guardia en proceso
    guardInProcess(contactList) {
      const existGuard = contactList.some(obj =>
        Object.keys(obj).includes("time_range")
      );
      if (!existGuard) return undefined;

      const date = new Date();
      const hour = date.getHours();
      const minute = date.getMinutes();
      // obtener el item que su rango de tiempo este dentro de la hora actual
      const item = contactList.find(item => {
        if (!item.time_range || item.status !== 1) return false;

        const start = item.time_range.start;
        const end = item.time_range.end;
        const currentTime = hour * 60 + minute;
        // no se toma en cuenta el minuto en curso
        // si el rango de tiempo es de 8:00 a 9:00, se toma en cuenta hasta las 8:59
        // por lo que debe de haber alguien que inicie a las 9:00 para que se tome en cuenta
        return start <= currentTime && currentTime < end;
      });
      return item;
    },
    saveGuard(time_range, idContact, location) {
      if (!time_range) return;

      location.contact_list.forEach(item => {
        if (item.idContact === idContact) item.time_range = time_range;
      });
      // recalcular cual es el current
      this.updateCurrentDateTime();
    }
  },
  computed: {
    colorComputed() {
      return "indigo darken-3";
    },
    getCurrentTime() {
      const hours = this.currentDateTime.getHours() * 60;
      const minutes = this.currentDateTime.getMinutes();
      return hours + minutes;
    }
  },
  beforeDestroy() {
    this.cleanInterval();
  }
};
</script>

<style scoped>
.recently {
  background-color: #cde4f7;
  /* cursor: grab; */
}
.normal {
  background-color: #ffff; /* f3f3f3 */
  /* cursor: grab; */
}
.disabled {
  background: #e9e9e9;
}
.btn-info {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 15px;
}
.carrusel-disabled {
  text-transform: none;
  font-weight: 400;
  font-size: 12px;
}
.aliases-container {
  display: flex;
  gap: 1ch;
  margin-bottom: 0.5rem;
}
.container-tags-name {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000de;
}
.sticky_notes {
  position: sticky;
  z-index: 100;
  bottom: 0;
  width: 100%;
  background-color: #fef8ff; /* f4f1ff; */
}
</style>
